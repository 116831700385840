import { useEffect, useState, useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import "./Dashboard.css";
import { auth, db, logout } from "./firebase";
import { doc, query, addDoc, updateDoc, collection, getDocs, where } from "firebase/firestore";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import moment from 'moment'
import { Form, Field } from 'react-final-form'
import {Input, SelectInput, PrefixInput } from './Form';
import {Account, Billing } from './types'
import {v4 as uuidV4} from 'uuid';

const navigation: {name:string; href: string, current: boolean}[] = [
]
const userNavigation: {name: string; onClick?: Function, href?:string}[] = [
  { name: 'Sign out', onClick: () => logout() },
]

const defaultAccount:Account = {
  customDomainTXTVerification: '',
  description:'',
  domain:'',
  name: '',
  apiKey: '',
  lastLocation: {
    latitude: '',
    longiture: '',
  },
  icon: 'Pin',
  instagram: '',
  youtube: ''
}

function Dashboard() {
  const [user, loading] = useAuthState(auth);
  const [account, setAccount] = useState<Account>(defaultAccount);
  const [billing, setBilling] = useState<null|Billing>(null);
  const [accountId, setAccountId] = useState<null|string>(null);
  const [domainEnabled, setDomainEnabled] = useState<boolean>(false)
  const history = useHistory();
  const fetchAccount = useCallback(async () => {
    try {
      const q = query(collection(db, "user_accounts"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      if (doc.docs.length) {
        const data = doc.docs[0].data();
        setAccountId(doc.docs[0].id);
        const acc = data as Account
        setAccount(acc);
        setDomainEnabled(!!data.domain)
      }
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  }, [user?.uid])
  const fetchBilling = useCallback(async () => {
    try {
      const q = query(collection(db, "billing"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      if (doc.docs.length) {
        const data = doc.docs[0].data();
        setBilling(data as Billing);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user?.uid])
  useEffect(() => {
    if (loading) return;
    if (!user) return history.replace("/");
    fetchAccount();
    fetchBilling();
  }, [user, loading, history, fetchAccount, fetchBilling]);
  const [copied, setCopied ] = useState<string>('COPY');
  async function onCreateAccount(payload: any) {
    payload = {
      ...payload,
      url: payload?.url.replace(/\//g, '')
    };
    if (user && !accountId) {
      await addDoc(collection(db, "user_accounts"), {
        uid: user.uid,
        ...payload,
        lastLocation: {
          latitude: -33.873664,
          longitude: 151.212513
        },
        apiKey: uuidV4(),
      });
    }
    if (user && account && accountId) {
      const ref = doc(db, 'user_accounts',accountId) 
      await updateDoc(ref,{
        uid: user.uid,
        ...payload,
        // lastLocation: {
        //   latitude: -33.873664,
        //   longitude: 151.212513
        // },
        apiKey: uuidV4(),
      });
    }
  }
  return (
    <>
    <div className="min-h-full">
      <Disclosure as="nav" className="bg-white border-b border-gray-200">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex w-full">
                  <div className="flex-shrink-0 flex items-center w-full">
                    <LocationMarkerIcon 
                      className="block h-12 w-auto"
                    />
                    <h1 className="flex-1">
                      tracklive.me
                    </h1>
                    <button onClick={() => {
                        logout();
                    }} className="hidden self-end lg:block hover:underline">
                      Sign Out
                    </button>
                  </div>
                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'border-indigo-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* <button
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (item.onClick) {
                                    item.onClick()
                                  }
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                        : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                      'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4">
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{account?.name}</div>
                    {/* <div className="text-sm font-medium text-gray-500">{yar}</div> */}
                  </div>
                  <button
                    type="button"
                    className="ml-auto bg-white flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      onClick={item.onClick ? () => {
                        if (item.onClick) item.onClick()
                      } : undefined}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <div className="py-10">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">Settings</h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 lg:flex lg:space-x-8">
            {/* Replace with your content */}
            {!!account && <> <div className="px-4 py-8 sm:px-0 lg:w-1/3">
              <div className="text-xl font-semibold pb-4">Mobile App Tracking Settings</div>
              <div className="text-md font-semibold text-gray-700">
                Step 1. Download
              </div>
              <div className="flex space-x-4 pb-4">
                <a href="https://apps.apple.com/us/app/overland-gps-tracker/id1292426766" className="block text-md text-blue-400 hover:text-blue-500">
                  iOS Download
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.openhumans.app.overland" className="block text-md text-blue-400 hover:text-blue-500">
                  Android Download
                </a>
              </div>
              <div className="text-md font-semibold text-gray-700">
                Step 2. Goto settings in the app <br /> 
              </div>
              <p className="text-base text-gray-700 py-4">
                Complete the following
              </p>
              <div className="bg-gray-100 text-black font-semibold rounded-md p-4 space-y-3">
                  <div className="flex w-full  pb-3">
                    <div className="flex-1">
                      Field
                    </div>
                    <div>
                      Value
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Receiver Endpoint
                    </div>
                    <div className="text-blue-600 cursor-pointer hover:text-blue-400" onClick={() => {
                      navigator.clipboard.writeText(`https://us-central1-gps-tracker-ccad4.cloudfunctions.net/app/ovl/track/${account.apiKey}`)
                      setCopied('COPIED!')
                      setTimeout(() => {
                        setCopied('COPY')
                      }, 1500);
                    }}>
                      {copied} 
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Tracking Enabled
                    </div>
                    <div>
                      True
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Significant Location
                    </div>
                    <div>
                      Disabled
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Activity Type
                    </div>
                    <div>
                      Fitness or Automotive
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Desired Accuracy
                    </div>
                    <div>
                      Your preference
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Defer Location Updates
                    </div>
                    <div>
                      Never
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Points Per Batch
                    </div>
                    <div>
                      50
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Pause Updates Automatically
                    </div>
                    <div>
                      Off
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Resume with Geofence
                    </div>
                    <div>
                      Off
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Include Tracking Stats
                    </div>
                    <div>
                      Off
                    </div>
                  </div>
                  <div className="flex w-full  text-gray-700">
                    <div className="flex-1">
                      Enable Notifications
                    </div>
                    <div>
                      Off
                    </div>
                  </div>
              </div>
            </div></>}
            {!!account && <div className="px-4 py-8 sm:px-0 lg:w-1/3">
              {!!account?.domain && <> <div>
                  <div className="text-xl font-semibold pb-4"> Custom Domain Settings</div>
                  <div className="text-md font-semibold">
                    Domain: {account?.domain ?? 'Not Set'}
                  </div>
                  <p className="text-base text-gray-700 py-4">
                    Below are your DNS settings to setup a custom domain. A HTTPS/SSL certificate will be issued within 24hrs, you may see a warning during this period.
                    Any issues after 24hrs, <a className="text-blue-400 hover:text-blue-500" href="mailto:m+gps@mikejam.es">Contact Support</a>
                  </p>
                  <div className="bg-gray-100 text-black font-semibold rounded-md p-4">
                      <div className="flex w-full justify-evenly pb-3">
                        <div>
                          Type
                        </div>
                        <div>
                          Name
                        </div>
                        <div>
                           IP Address (Content)
                        </div>
                      </div>
                      <div className="flex w-full justify-evenly text-gray-700">
                        <div>
                          A
                        </div>
                        <div>
                          {account.domain}
                        </div>
                        <div>
                          199.36.158.100
                        </div>
                      </div>
                  </div>
                </div></>}
            </div>}
            {!!billing && <div className="lg:w-1/3">
              <div className="text-2xl font-semibold pb-4">Billing</div>
              <div className="text-md font-semibold pb-4">
                Paid Until: {moment(billing?.paidUntil).format('LLL')}
              </div>
            </div>}
            <div className="px-4 py-8 sm:px-0 w-full lg:w-1/3">
               <div className="text-xl font-semibold pb-4">Edit Tracking Page</div>
                <Form
                  initialValues={account}
                  onSubmit={onCreateAccount}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
                      <div>
                        <div className="mt-1">
                          <Field
                            component={Input}
                            type="text"
                            name="name"
                            id="name"
                            label="Name"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div>
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Add your description
                      </label>
                      <div className="mt-1">
                        <Field 
                          name="description" 
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
                          component="textarea"
                          rows={4}
                          placeholder="Description" 
                        />
                      </div>
                      </div>
                      {domainEnabled && <div>
                        <Field
                          component={Input}
                          type="text"
                          name="domain"
                          id="domain"
                          label="Custom Domain"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Domain"
                        />
                      </div>}
                      {!domainEnabled && <div className="flex flex-row">
                        <Field
                          component={PrefixInput}
                          prefix="https://tracklive.me/"
                          type="text"
                          name="url"
                          id="ulr"
                          label="tracklive.me url"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="bob-runs"
                        />
                      </div>}
                      <div>
                      <div>
                        <Field<string> name="icon" label="Tracking Icon" component={SelectInput}>
                          <option/>
                          <option value="runner">Runner</option>
                          <option value="female-runner">Female Runner</option>
                          <option value="male-runner">Male Runner</option>
                          <option value="boots">Hiking Boots</option>
                          <option value="pin">Pin</option>
                          <option value="feet">Bare Feet</option>
                          <option value="bike">Bike</option>
                          <option value="car">Car</option>
                        </Field>
                      </div>
                      </div>
                      <div>
                        <div className="mt-1">
                          <Field
                            component={Input}
                            type="text"
                            name="instagram"
                            id="instagram"
                            label="Instagram Username (Optional)"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="instame"
                          />
                        </div>
                        <div className="mt-1">
                          <Field
                            component={Input}
                            type="text"
                            name="charity"
                            id="charity"
                            label="Charity Page (Optional)"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="https://gofundme.com/donate-to-my-run"
                          />
                        </div>
                        <div className="mt-1">
                          <Field
                            component={Input}
                            type="text"
                            name="youtube"
                            id="youtube"
                            label="Youtube Channel (Optional)"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="https://www.youtube.com/channel/UCuAXFkgsw1L7xaCfnd5JJOw"
                          />
                        </div>
                      </div>
                      <button type="submit">Submit</button>
                    </form>
                  )}
                />
            </div>
            {/* /End replace */}
          </div>
        </main>
      </div>
    </div>
  </>
  );
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}


export default Dashboard;