import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route exact path="/"><Login /></Route>
          <Route exact path="/register" ><Register /> </Route>
          <Route exact path="/reset" ><Reset /> </Route>
          <Route exact path="/dashboard"><Dashboard /> </Route>
        </Switch>
      </Router>
    </div>
  );
}
export default App;
