import { FieldRenderProps } from "react-final-form";

type InputProps = FieldRenderProps<string, any>;
export const Input: React.FC<InputProps> = ({ input, meta, ...rest }: InputProps) => (
  <div>
      <label htmlFor={rest.id} className="block text-sm font-medium text-gray-700">
        {rest.label}
      </label>
      <div className="mt-1">
        <input
          {...input}
          {...rest}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
);

type PrefixInputProps = FieldRenderProps<string, any>;
export const PrefixInput: React.FC<PrefixInputProps> = ({ input, prefix, meta, ...rest }: PrefixInputProps) => (
  <div>
      <label htmlFor={rest.id} className="block text-sm font-medium text-gray-700">
        {rest.label}
      </label>
      <div className="mt-1">
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"> {prefix} </span>
        <input 
          {...input}
          {...rest}
         className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
         />
      </div>
      </div>
    </div>
);

type SelectProps = FieldRenderProps<string, any>;

export const SelectInput: React.FC<SelectProps> = ({ input, meta, ...rest }: SelectProps) => (
  <>
    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
      {rest.label}
    </label>
    <select
      {...input} {...rest} 
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    />
  </>
);

